export default function throttle(fn, interval) {
    var pending = false;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (pending)
            return;
        pending = true;
        fn.apply(void 0, args);
        setTimeout(function () { return (pending = false); }, interval);
    };
}
